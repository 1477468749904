@import url("https://use.typekit.net/flu0irq.css");
.menu {
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(122.19deg, #da2079 1.89%, #2a00d5 113.56%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.menu-wrapper,
.container {
  padding: 0 !important;
  margin: 0 !important;
}

#menus-container {
  width: 100%;
  margin: 0 auto;
}

#menu-inner {
  padding: 30px;
}

h1 {
  color: white;
}

#crumbs {
  font-family: "forma-djr-display";
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.05em;
}

.crumbs-item a {
  color: rgb(213, 213, 213);
  text-decoration: none;
}

.crumbs-item a:hover {
  background: -webkit-linear-gradient(rgb(135, 234, 49), rgb(89, 207, 89));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.crumbs-item a:hover,
.active {
  color: rgb(226, 226, 226) !important;
  text-decoration: underline;
  text-underline-offset: 0.2em;
}

.round-boys {
  font-family: "forma-djr-display";
  letter-spacing: 0.05em;
  font-weight: 600;
  border-radius: 90px;
  padding: 30px;
  width: 800px;
  font-size: 80px !important;
  text-transform: uppercase;
  border: 5px solid white;
  color: white;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 5px solid rgba(255, 255, 255, 0.18);
}

.round-boys:hover {
  transition: 0.5s;
  color: rgba(255, 255, 255, 0.754);
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  border: 5px solid rgba(255, 255, 255, 0.18);
}

.good-guy-thumb-container {
  position: absolute;
  bottom: 0;
  right: 0;
}

.good-guy-thumb {
  opacity: 0.125;
  margin: 20px;
  width: 225px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 850px) {
  .round-boys {
    font-family: "forma-djr-display";
    letter-spacing: 0.05em;
    font-weight: 600;
    border-radius: 90px;
    padding: 30px;
    width: 40rem;
    font-size: 60px !important;
    text-transform: uppercase;
    border: 5px solid white;
    color: white;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 5px solid rgba(255, 255, 255, 0.18);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 700px) {
  .round-boys {
    font-family: "forma-djr-display";
    letter-spacing: 0.05em;
    font-weight: 600;
    border-radius: 90px;
    padding: 30px;
    width: 30rem;
    font-size: 40px !important;
    text-transform: uppercase;
    border: 5px solid white;
    color: white;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 5px solid rgba(255, 255, 255, 0.18);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 550px) {
    .round-boys {
      font-family: "forma-djr-display";
      letter-spacing: 0.05em;
      font-weight: 600;
      border-radius: 90px;
      padding: 30px;
      width: 25rem;
      font-size: 30px !important;
      text-transform: uppercase;
      border: 5px solid white;
      color: white;
      display: inline-block;
      text-decoration: none;
      text-align: center;
      background: rgba(255, 255, 255, 0.25);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      border: 5px solid rgba(255, 255, 255, 0.18);
    }

    .good-guy-thumb {
      margin: 20px;
      width: 150px;
    }
  }
  