@import url('https://use.typekit.net/flu0irq.css');
.menu {
    min-height: 100vh;
    width: 100vw;
    background: linear-gradient(122.19deg, #da2079 1.89%, #2a00d5 113.56%);
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.menu-wrapper,
.container {
    padding: 0 !important;
    margin: 0 !important;
}

.sidebar-container {
    background-color: #332D2D;
}

.sidebar-tab {
    background-color: #c9c9c9;
    border-color: blue;
}

#sidebar-menu {
    padding: 20px 10px;
}

.footer-image {
    width: 60%;
    /* margin-top: 90vh; */
    z-index: 9999;
}

#menu-container, .input-container {
    width: calc(100vw - 30vw);
    left: 30vw;
    position: relative;
}

#menu-inner {
    padding: 30px;
}

.add-confirm-icon {
    position: absolute;
    transform: scale(2);
    top: 27px;
    right: 750px;
    cursor: pointer;
}

.todo>h1 {
    width: 500px;
    background-color: rgb(211, 211, 211);
    padding: 5px 10px;
}

.edit-button,
.delete-button {
    cursor: pointer;
    margin-left: 10px;
}

button {
    width: auto;
}

input,
textarea {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    margin: 0 auto;
    box-shadow: 0 13px 21px rgba(0, 0, 0, 0.05);
    color: white
}

input::placeholder,
textarea::placeholder {
    color: white;
}

h1 {
    color: white;
}

.accordion button {
    box-shadow: none;
}

h1,
.nav-link {
    font-family: forma-djr-display, sans-serif !important;
    font-weight: 700 !important;
    font-size: 52px !important;
    font-style: normal;
}

.MuiListItemText-primary {
    font-family: forma-djr-display, sans-serif !important;
    font-weight: 700 !important;
    font-size: 38px !important;
}

#sidebar-subheader {
    font-family: forma-djr-display, sans-serif !important;
    font-weight: 700 !important;
}

.nav-link {
    color: white;
    padding: 0 15px;
}

.sidebar-nav-link {
    color: white;
    padding: 0;
}

.nav-link:hover, .sidebar-nav-link:hover {
    transition: 0.6s;
    background: -webkit-linear-gradient(rgb(135, 234, 49), rgb(89, 207, 89));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#sidebar-signout {
    /* margin-top: 250px; */
    bottom: 0 !important;
}

#sidebar-signout .sidebar-nav-link {
    font-weight: 700 !important;
    color: gray !important;
}

#crumbs {
    font-family: "forma-djr-display";
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.05em;
}

.crumbs-item a {
    color: #c2c2c2;
    text-decoration: none;
}

.crumbs-item a:hover {
    background: -webkit-linear-gradient(rgb(135, 234, 49), rgb(89, 207, 89));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.crumbs-item.active {
    color: white;
    text-decoration: underline;
    text-underline-offset: 0.2em;
}