@import url("https://fonts.googleapis.com/css2?family=Changa+One&display=swap");

.welcome {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(122.19deg, rgb(89, 207, 89) 1.89%, rgb(11, 13, 14) 143.56%);
  position: relative;
  overflow: hidden;
}

.welcome > h1 {
  font-family: "Changa One";
  font-weight: normal;
  font-size: 144px;
  left: 46px;
  top: 155px;
  position: absolute;
  color: white;
}

h1,
.nav-link {
    font-family: forma-djr-display, sans-serif !important;
    font-weight: 700 !important;
    font-size: 52px !important;
    font-style: normal;
}

.login-register-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 50px;
  left: 150px;
  top: 360px;
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba(20, 23, 60, 0.37);
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.login-register-container > input {
  height: 30px;
  width: 80%;
  border: 1px solid gray;
  outline: none;
  margin-bottom: 15px;
  font-size: 25px;
  padding: 5px;
}

.login-register-container > button {
  padding: 5px 20px;
  border: none;
  margin-bottom: 5px;
  cursor: pointer;
}

/* .sign-in-register-button {
  border-radius: 20px;
  font-size: 20px;
  margin-top: 10px;
  font-family: forma-djr-display, sans-serif;
  font-weight: normal;
  color: white;
} */

.create-account-button {
  background: none;
  margin-top: 10px;
}

.GoodGuy {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 40%;
}

input {
  font-size: 16px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 20px auto;
  padding: 12px 20px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 13px 21px rgba(0, 0, 0, 0.05);
}

button {
  margin-top: 15px;
  width: 80%;
  background-color: hsla(0, 0%, 100%, 0.7);
  color: #212121;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 20px;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid gray;
  outline: none;
  margin-bottom: 15px;
  font-size: 20px;
  padding: 5px;
  box-shadow: 0 13px 21px rgba(0, 0, 0, 0.15);
}

input {
  padding: 20px 10px !important;
}

input:focus {
  outline: none;
}

input::placeholder {
  color: white;
}

button:hover {
  background: hsla(0, 0%, 100%, 0.4);
  box-shadow: 0 13px 21px rgba(0, 0, 0, 0.2);
}

.forgot-signup {
  display: flex;
  justify-content: space-between;;
}