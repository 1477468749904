
#crumbs {
    padding-top: 50px !important;
}

.sidebar-container {
    background-color: #332D2D !important;
}

.toolbar-container {
    background-color: #222020 !important;
}

.sidebar-tab {
    font-weight: 600;
    padding: 15 0;
}

.toolbar-container div {
    font-family: "forma-djr-display";
    font-weight: 600;
    color: 0;
    padding: 0 !important;
}