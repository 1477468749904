.homepage {
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(122.19deg, #da2079 1.89%, #2a00d5 113.56%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.menu-wrapper,
.container {
    padding: 0 !important;
    margin: 0 !important;
}

#admin-sidebar-container {
  max-height: 100vh !important;
  background-color: #332D2D;
  width: 30%;
  position: fixed;
  top: 0;
  left: 0;
}

#sidebar-menu {
  padding: 20px 10px;
}

#admin-inventory-container {
  width: calc(100vw - 30vw);
  left: 30vw;
  position: relative;
  padding: 30px;
}

#admin-inventory-input {
  padding: 30px;
}

.input-container, .menu-container {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 20px;
  margin: 100px 0;
}

.color {
  outline: none;
  border: none;
  height: 30px;
  font-size: 25px;
  padding: 20px 20px;
  border-radius: 10px;
}

#flavors-chip-label,
#size-chip-label,
#strength-chip-label {
  /* font-size: 20px; */
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 15px !important;
  margin: -10px 0 !important;
}

#admin-inventory-input, .menu-container {
  margin-bottom: 50px !important;
}

.menu-container {
  padding: 20px !important;
}

.color-textarea {
  outline: none;
  border: none;
  font-size: 25px;
  padding: 20px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.add-confirm-icon {
  transform: scale(2);
  top: 27px;
  right: 750px;
  cursor: pointer;
}


.item-accordion {
  margin: 3px 0;
}

.edit-button,
.delete-button {
  cursor: pointer;
  margin-left: 10px;
}

.logout-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 3px;
  background: rgba( 255, 0, 0, 0.8 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );  color: white !important;
}